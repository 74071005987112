// src/plugins/http.ts

import axios from 'axios'
import toast from '@plugins/toast'

function axiosGet(url: string): Promise<any> {
  return axios.get(url)
}

function axiosPost(url: string, data?: object): Promise<any> {
  return axios.post(url, data)
}

function get(url: string): Promise<any> {
  return new Promise((resolve) => {
    axiosGet('/api' + url)
      .then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          location.href =
            '/login?redirect=' +
            encodeURIComponent(location.pathname + location.search)
        } else {
          toast({ word: res.data.msg })
        }
      })
      .catch((err) => {
        toast({ word: err })
      })
  })
}

function post(url: string, data?: object): Promise<any> {
  return new Promise((resolve, reject) => {
    axiosPost('/api' + url, data)
      .then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else if (res.data.code === 1) {
          location.href =
            '/login?redirect=' +
            encodeURIComponent(location.pathname + location.search)
        } else {
          toast({ word: res.data.msg })
          reject()
        }
      })
      .catch((err) => {
        toast({ word: err })
        reject()
      })
  })
}

interface Http {
  get: (url: string) => Promise<any>
  post: (url: string, data?: object) => Promise<any>
}

const http: Http = {
  get: get,
  post: post,
}

export default http
