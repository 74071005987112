import http from '@/plugins/http'
export const getCookie = (cname: string) => {
  const name = cname + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const clearAllCookie = () => {
  const date = new Date()
  date.setTime(date.getTime() - 10000)
  const keys = document.cookie.match(/[^ =;]+(?==)/g)
  if (keys) {
    for (let i = keys.length; i--; )
      document.cookie =
        keys[i] + '=0; expire=' + date.toUTCString() + '; path=/'
  }
}

export const copyToClipboard = (value: string): boolean => {
  // 1、创建DOM input框
  const input = document.createElement('input')
  // 2、隐藏input
  // input.style = { opacity: 0, height: 0, lineHeight: 0, fontSize: 0 }
  // 3、将指定文本赋值给input
  input.value = value
  // 4、将input插入文档
  document.body.appendChild(input)
  // 5、选中文本
  input.select()
  // 6、复制到剪切板
  const copyRes = document.execCommand('copy')
  // 7、复制成功后提示
  // 8、销毁DOM
  document.body.removeChild(input)
  return copyRes
}

// 纵向铺满，横向扩展
export const resizeRootFontSize = () => {
  // 0.0133333333由$vw_fontSize/$vw_height_design计算得来
  const size = 0.0092592592592593 * window.innerHeight
  const h = document.querySelector('html')
  h ? (h.style.fontSize = size + 'px') : null
}
// 横向铺满，纵向扩展
export const restoreRootFontSize = () => {
  // 0.0133333333由$vw_fontSize/$vw_width_design计算得来
  const size = 0.0052083333333333 * window.innerWidth
  const h = document.querySelector('html')
  h ? (h.style.fontSize = size + 'px') : null
  setTimeout(() => {}, 1000)
}

// 如果是创作者，创作者可点击头像进入自己的主页
export const goToAuthPage = async () => {
  let currentUserRole = 0
  let currentUserId = ''
  const userInfo = await http.get('/user/info')
  if (userInfo && userInfo.id !== '') {
    currentUserRole = userInfo.role
    currentUserId = userInfo.id
  } else {
    return
  }
  if (currentUserRole === 2) {
    window.open(`/home?uid=${currentUserId}&currentUserRole=2`)
  }
}

// 跳转到关于我们
export const openNewWindow = (url: string) => {
  window.open(url)
}
