<template>
  <!--  <nav>-->
  <!--    <router-link to="/">Home</router-link> |-->
  <!--    <router-link to="/about">About</router-link>-->
  <!--  </nav>-->
  <router-view />
  <Toast />
</template>

<script setup lang="ts">
import Toast from '@cp/Toast.vue'

import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import { restoreRootFontSize } from '@/libs/utils'
// const store = useStore()
// const loginPage = computed(() => store.loginPage)
let loginPage = computed(() => 'none')
const router = useRouter()
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    console.log('watch', newValue)
    if (newValue === '/login') {
      loginPage = computed(() => 'initial')
    }
    if (oldValue === '/login') {
      loginPage = computed(() => 'none')
      window.addEventListener('resize', () => {
        restoreRootFontSize()
      })
    }
  },
  {
    immediate: true,
  }
)
</script>

<style lang="scss">
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}*/
@import '@/size.scss';
// @import '@/size750.scss';

html,
body {
  margin: 0;
  padding: 0;
  // font-size: if
  //   (
  //     v-bind(loginPage),
  //     ($vw_fontSize / $vw_height_design) * 100vh,
  //     ($vw_fontSize / $vw_width_design) * 100vw
  //   );
  // font-size: ($vw_fontSize / $vw_width_design) * 100vw;
  // font-size: 10px;
  width: 100% !important;
  height: 100%;
  box-sizing: border-box;
  scrollbar-width: none;
}
p {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 750px) {
  html,
  body {
    font-size: ($vw_fontSize / 1920) * 100vw;
    
  }
}
@media screen and (max-width: 750px) {
  html,
  body {
    font-size: ($vw_fontSize / 750) * 100vw;
  }
}
::-webkit-scrollbar {
  display: v-bind(loginPage);
  height: px2rem(10);
}
::-webkit-scrollbar-thumb {
  background-color: darkgray;
}
// App.vue


</style>