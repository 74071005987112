// src/plugins/toast.ts

/**
 * 弹出提示语
 * @param word - 提示语具体内容
 */
import { Subject } from 'rxjs'

const subject = new Subject()

export default function toast(obj: ToastObj) {
  subject.next(obj)
}

export function toastGet(): any {
  return subject.asObservable()
}

export interface ToastObj {
  word: string
  duration?: number // 提示语显示的时间，单位秒，非必传，默认1秒钟
}
